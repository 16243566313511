<div id="delphire-task" *ngIf="task" [ngClass]="task.type == 'None' || task.type == 'url' ? class + ' text-only md-padding' : class + ' md-padding' " fxLayout.xs="column">
  <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
    <div class="{{ class }}-info" fxFlex="65" fxLayout="column" fxFlex.xs="100">
      <h4 *ngIf="task.name">{{ task.name }}</h4>
      <h4 *ngIf="!task.name && task.type != 'url'">Text</h4>
      <!-- Handle bad course builder data for Text Only Item -->
      <div *ngIf="task.type == 'url' || task.body" class="{{ class }}-item-description" [innerHtml]="task.body"></div>
      <div class="{{ class }}-description" *ngIf="task.description" [innerHtml]="task.description"></div>
      <div class="{{ class }}-details" fxLayout="row" *ngIf="task?.type != 'None'" fxLayoutAlign="start center">
        <div fxFlex="25" class="{{ class }}-type">
          <ng-icon class="icon" name="{{ getTypeIcon(task.type) }}" size="20px"></ng-icon>
          <span class="{{ class }}-capitalize" *ngIf="
              task?.type == 'Resource' && task?.resource?.type != 'package'
            ">{{ task.resource?.type }}</span>
          <span class="{{ class }}-capitalize" *ngIf="
              task?.type == 'Resource' && task?.resource?.type == 'package'
            ">Module</span>
          <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Course'">Series</span>

          <span *ngIf="
              task?.type != 'Resource' &&
              task?.type != 'Course' &&
              task.type != 'url'
            ">{{ task.type }}</span>
        </div>
        <ng-container *ngIf="task.type != 'url'">
          <div *ngIf="
              task?.resource?.type != 'audio' &&
              task?.resource?.type != 'video' &&
              task.duration
            " fxFlex="25" class="{{ class }}-duration">
            <ng-icon name="HeroClock" size="15px"></ng-icon>
            {{ task.duration }}
            <span [innerHtml]="language.widget.minutes"></span>
          </div>
          <div *ngIf="
              (task?.resource?.type == 'audio' && task.duration) ||
              (task?.resource?.type == 'video' && task.duration)
            " fxFlex="25" class="{{ class }}-duration">
            <ng-icon name="HeroClock" size="15px"></ng-icon>
            {{ task.duration | timeFormat }}
          </div>
        </ng-container>
      </div>
      <div class="{{ class }}-progress" *ngIf="
          trackProgress(task) && task.type !== 'None' && task.type !== 'url' && task.type !== 'External Link'">
        <p class="percent">{{ getCompletionPercent(task) }}% complete</p>
        <mat-progress-bar mode="determinate" value="{{ getCompletionPercent(task) }}"></mat-progress-bar>
      </div>
    </div>
    <div fxFlex="5"></div>
    <div fxFlex="30" fxLayout="row" fxLayoutAlign="end start" fxLayoutAlign.xs="center center" class="action-items">
      <div class="{{ class }}-favorite"  fxFlex="100" fxLayout="row" fxFlex="15" fxFlex.xs="10" fxLayoutAlign="start start">
        <!-- <ng-container *ngIf="canFavorite(task)"> -->
          <div *ngIf="task.type != 'None'">
            <ng-icon matTooltip="Add Favorite" matTooltipPosition="above" class="hand roadmap-not-favorite"
              name="{{ getTypeIcon('favorite') }}" size="20px" [hidden]="task.isFavorite" (click)="addFavorite(task)">
            </ng-icon>
            <ng-icon matTooltip="Remove Favorite" matTooltipPosition="above" class="hand roadmap-is-favorite"
              name="{{ getTypeIcon('favorite', true) }}" size="20px" [hidden]="!task.isFavorite" (click)="addFavorite(task)">
            </ng-icon>
          </div>
        <!-- </ng-container> -->
      </div>
      <div class="{{ class }}-actions" fxLayout="column" fxLayoutAlign="start start" fxFlex [ngClass]="getCompletionStatus(task)">
        <div *ngIf="task.type != 'None' && task.type != 'url'">
          <button *ngIf="download.active" [disabled]="true" class="roadmap-btn roadmap-btn-loading mat-raised-button"
            fxLayoutAlign="start center">
            <mat-progress-spinner mat-primary mat-diameter="15px" class="btn-loader"></mat-progress-spinner>
            <span class="loader-span">Loading</span>
          </button>
          <button class="roadmap-btn roadmap-btn-start mat-raised-button" (click)="onOpenClick(task)"
            *ngIf="!download.active && !trackProgress(task)">
            Launch
          </button>
          <button class="roadmap-btn roadmap-btn-start mat-raised-button" (click)="onOpenClick(task)" *ngIf="
              !download.active &&
              trackProgress(task) &&
              getCompletionStatus(task) == 'start'
            ">
            {{ progressCopy1(task) }}
          </button>
          <button class="roadmap-btn roadmap-btn-resume mat-raised-button" (click)="onOpenClick(task)" *ngIf="
              !download.active &&
              trackProgress(task) &&
              getCompletionStatus(task) == 'resume'
            ">
            Resume
          </button>
        </div>
        <button class="roadmap-btn roadmap-btn-complete mat-raised-button" (click)="onOpenClick(task)" *ngIf="
            !download.active &&
            trackProgress(task) &&
            getCompletionStatus(task) == 'complete'
          ">
          {{ progressCopy3(task) }}
        </button>
        <!--? SELF -->
        <div *ngIf="(task.selfCompletion && task.selfCompletion!.enabled) || (taskAcknowledgement(task))">
          <button class="roadmap-btn roadmap-btn-self-complete mat-raised-button" [ngClass]="{ completed: task.selfCompleted }" [disabled]="!shouldEnableSelfComplete(task)" (click)="selfComplete(task)">
            {{ progressCopy4(task) }}
          </button>
        </div>
        <!--? COMPLETE -->
      </div>

    </div>
  </div>
</div>