<div class="home" [ngStyle]="{
    height: '100%',
    padding: '5px 2px 105px 2px'
  }">
  <router-outlet (activate)="outletActivated()" (deactivate)="outletDeactivated()"></router-outlet>
  <div class="home-view" *ngIf="shouldShowHomePage" fxLayout="column">
    <div class="hero" [ngStyle]="{
        'background-image': 'url(' + heroBackgroundUrl + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center left'
      }" fxFlex="33" fxLayout="row" fxLayoutGap="4" fxLayoutAlign="space-between center">
      <div class="userInfo" fxLayoutGap="4" fxFlex="100" fxLayoutAlign="space-between left" fxLayout="column">
        <p class="login">
          Last login was on
          {{ user?.updated_at | date: 'EEE, MMM dd, y, h:mm a' }}
        </p>
        <h1>Welcome, {{ user?.given_name }}!</h1>

        <div class="info-container" fxLayout="row">
          <div fxLayout="row">
            <img [src]="user?.picture" referrerpolicy="no-referrer" mat-card-avatar />
          </div>

          <div fxLayout="column" style="padding-top: 11px;">
            <p style="
                margin-bottom: 0px;
                color: black;
                font-family: 'Roboto';
                font-size: .85em;
                margin-bottom: 5px;
              ">
              Displaying Content for:
            </p>
            <span class="groupname">{{ selectedGroup?.name }}</span>
            <!-- if you want to restrict the group selector from the home page <mat-form-field *ngIf="currentPathSpace !== 'customer-engagement/home'" appearance="fill" class="group-selector"> -->
            <mat-form-field appearance="fill" class="group-selector">
              <div class="px-3 selector-label"><img class="px-2 filter-white" src="/images/icons/arrows-right-left.svg" /> Change Content Display</div>
              <mat-select (selectionChange)="handleGroupSelect($event.value)" class="selector-panel"  (openedChange)="emptyInput()">
                <input class="selector-input" placeholder="Filter" [(ngModel)]="searchKeyword"(keyup)="onKey($event)">
                <mat-option *ngFor="let group of selectedGroups" [value]="group.groupId">{{group.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxHide.lt-md class="userInfo" fxLayoutGap="4" fxFlex="25" fxLayoutAlign="space-between right"
        fxLayout="column" *ngIf="lastTaskInProgress && lastTaskInProgress.id !== ''">
        <h2>Pick Up Where I Left Off...</h2>
        <app-task-card [item]="lastTaskInProgress"></app-task-card>
      </div>
    </div>

    <!-- <ng-container *ngIf="currentPathSpace !== 'customer-engagement/home'" > -->
    <ng-container>
      <button mat-button type="button" (click)="goToLearningPortal()" class="nav-button" id="goToLearningPortal">
        <span>Explore more learning with the CMG Learning Portal</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
        </svg>
      </button>
    </ng-container>

    <ng-container *ngIf="landingPageSections?.sections">
      <ng-container *ngFor="let section of landingPageSections.sections">
        <div class="inProgress" flexLayout="row" fxFlex="100" *ngIf="librariesLoaded">
          <h2 style="display: inline-flex" *ngIf="section.type == 'progress'" [innerHtml]="
              progressType === 'In Progress' ? 'In Progress' : 'Completed Items'
            "></h2>
          <h2 *ngIf="section.type == 'favorites'">My Favorites</h2>
          <h2 *ngIf="section.type == 'spaces'">Other Spaces</h2>
          <h2 *ngIf="section.type == 'library'">
            {{ libraries | libraryName: section.libraryId }}
          </h2>
          <button mat-button (click)="toggleProgress()" class="toggleProgress"
            *ngIf="section.type == 'progress' && lastTaskInProgress && lastTaskInProgress.id !== ''" [innerHtml]="
              progressType === 'In Progress'
                ? 'Display Completed Items'
                : 'Display In Progress Items'
            "></button>
          <div fxLayout="row" class="scrollList" [ngSwitch]="section.type" #scrollView #inProgress>
            <div appShowMore [parentElem]="scrollView" direction="left"
              [ngClass]="section.type === 'spaces' ? 'less spaces' : 'less'">
              <ng-icon name="hero-chevron-left" size="25px"></ng-icon>
            </div>
            <!-- Progress Cards -->
            <ng-container *ngSwitchCase="'progress'">
              <ng-container *ngIf="userTasksInProgress?.length == 0">
                <img style="width: 303px; height: 187px" src="images/placeholder-noprogress-2x.png" />
              </ng-container>
              <ng-container *ngFor="let item of userTasksInProgress">
                <app-task-card [item]="item" [progressType]="progressType" [section]="'progress'"
                  (newTaskEvent)="newTaskSelected($event)" *ngIf="!item.archived">
                </app-task-card>
              </ng-container>
            </ng-container>
            <!-- Favorites Cards -->
            <ng-container *ngSwitchCase="'favorites'">
              <ng-container *ngIf="favoritesArray.length == 0">
                <img style="width: 303px; height: 187px" src="images/placeholder-nofavs-2x.png" />
              </ng-container>
              <ng-container *ngIf="favoritesArray.length">
                <ng-container *ngFor="let favorite of favoritesArray">
                  <app-task-card [section]="'favorites'" [item]="favorite">
                  </app-task-card>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Libraries -->
            <ng-container *ngSwitchCase="'library'">
              <ng-container *ngIf="section.type == 'library'">
                <ng-container *ngFor="let item of libraryTasksByKey | keyvalue">
                  <ng-container *ngIf="item.key == section.libraryId">
                    <ng-container *ngFor="let task of libraryTasksByKey[section.libraryId]">
                      <app-task-card [item]="task" [section]="'library'" (newTaskEvent)="newTaskSelected($event)">
                      </app-task-card>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Spaces -->
            <ng-container *ngSwitchCase="'spaces'">
              <ng-container *ngFor="let space of spaces | orderBy: 'name':true">
                <div class="space" (click)="selectSpace(space.id)">
                  <img mat-card-image [src]="space.imageUrl | async" />
                </div>
              </ng-container>
            </ng-container>
            <div appShowMore [parentElem]="scrollView" direction="right"
              [ngClass]="section.type === 'spaces' ? 'more spaces' : 'more'">
              <ng-icon name="hero-chevron-right" size="25px"></ng-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
