<div id="confirmation-dialog" class="header">
  <h4 mat-dialog-title>{{ data.title }}</h4>
</div>
<div mat-dialog-content>
  <div class="dialog-message" [innerHtml]="data.message"></div>
  <form
    class="rememberChoice"
    [formGroup]="preferences"
    *ngIf="data.rememberChoice"
  >
    <mat-checkbox formControlName="dontShowAgain"
      >Don’t show me this again until next time I log in.</mat-checkbox
    >
  </form>
</div>
<div
  class="dialog-buttons"
  mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="end start"
>
  <button
    fxShow="data.confirmText "
    class="mat-raised-button btn-confirm"
    (click)="confirm()"
  >
    {{ data.confirmText }}
  </button>
  <button class="mat-raised-button btn-cancel" (click)="cancel()">
    {{ data.cancelText }}
  </button>
</div>
